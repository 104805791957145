import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["navElement", "navLink"]
  static values = { navContext: String }

  connect() {
    const parentContext = this.contextWithoutLastElement(this.navContextValue)
    this.showContext(parentContext)
    this.setLinksActive()
  }

  showPreviousContext(event) {
    const previousContext = this.contextWithoutLastElement(event.target.dataset.navContext)
    this.showContext(previousContext)
  }

  showNewContext(event) {
    const newContext = event.target.dataset.navContext
    this.showContext(newContext)
  }

  showContext(context) {
    this.showElementsWithContext(context)
    this.showLinksWithContext(context)
  }

  showElementsWithContext(context) {
    this.navElementTargets.forEach(elementTarget => {
      if(elementTarget.dataset.navContext == context) {
        elementTarget.classList.remove("d-none")
      } else {
        elementTarget.classList.add("d-none")
      }
    });
  }

  showLinksWithContext(context) {
    this.navLinkTargets.forEach(linkTarget => {
      const linkParentContext = this.contextWithoutLastElement(linkTarget.dataset.navContext)
      if(linkParentContext == context) {
        linkTarget.classList.remove("d-none")
      } else {
        linkTarget.classList.add("d-none")
      }
    });
  }

  // Sets all links along the nav path to active
  setLinksActive() {
    this.navLinkTargets.forEach(linkTarget => {
      if(this.navContextValue.startsWith(linkTarget.dataset.navContext)) {
        linkTarget.classList.add("mdl-navigation__link--active")
      }
    });
  }

  // Returns the nav context excluding the last value, ex:
  // input: "configuration--contract--terms"
  // output: "configuration--contract"
  contextWithoutLastElement(context) {
    const stopIndex = context.lastIndexOf('--')
    return context.substring(0, stopIndex)
  }
}
